





































































































import {
  defineComponent,
  useContext,
  useRouter,
  useRoute,
  computed,
  useFetch, ref, onBeforeMount, onMounted
} from '@nuxtjs/composition-api';
import { useSidebarLinkGroups } from './useSidebarLinkGroups';
import { useUser } from '../../composables/useUser';
import { useCart } from '~/modules/checkout/composables/useCart';
import Icon from "@/almarwan/components/Icon.vue";
import {SfNotification} from '@storefront-ui/vue';
import userGetters from '~/modules/customer/getters/userGetters';
import ImageInput from '~/modules/customer/pages/MyAccount/MyProfile/ProfilePicture.vue';
import {eventBus} from "@/almarwan/helpers/EventBus";
import {useUiNotification} from "~/composables";

export default defineComponent({
  name: 'MyAccount',
  middleware: 'is-authenticated',
  components: {
    SfNotification,
    Icon,
    ImageInput
  },
  methods: {
    async signout() {
      await this.logout({});
      await this.clear({});
      eventBus.$emit('updateWishlistInAppHeader')
      await this.$router.push(this.localeRoute({ name: 'home' }));
    },
    goToPage(url) {
      var localPath = this.localeRoute(url)
      if(this.$route.path == localPath.path) {
        eventBus.$emit('toggleProfileMenu', true);
      }
    }
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { i18n, localeRoute } = useContext();
    const { user, load: loadUser, logout, updateCustomerPicture } = useUser();
    const { clear } = useCart();
    const { send: sendNotification } = useUiNotification();
    const { isAuthenticated } = useUser();
    const avatar = ref(null)
    const avatarName = ref(null)
    const hideAside = ref(true);
    const visible = ref(false);

    const getUserInitial = computed(() => {
      let name = userDetails.value.firstname
      if (name) {
        let splitName = name.split(" ");

        if (splitName.length === 1) {
          return userDetails.value.firstname[0]
        }

        let slug_1 = splitName[0];
        let slug_2 = splitName.pop();
        return slug_1[0] + slug_2[0]
      }
    });

    useFetch(async () => {
      if (user.value === null) {
        await loadUser();
      }
    });

    const { sidebarLinkGroups } = useSidebarLinkGroups();

    const isOnSubpage = computed(() => route.value.matched.length > 1);
    const goToTopLevelRoute = () => router.push(localeRoute({ name: 'customer' }));
    const title = computed(() => i18n.t(route.value.matched.at(-1)?.meta.titleLabel as string));

    const userDetails = computed(() => {
      return {
        firstname: userGetters.getFirstName(user.value),
        profilePic: userGetters.getProfilePicture(user.value)
      }
    });

    const getBase64 = (file) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload =  () => {
          avatar.value = reader.result
          makeImageUpdate({
            file_name: avatarName.value,
            file_content: reader.result
          })

        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      }
    const uploadFile =  (event) => {
      if(event == '') visible.value = true;
      else {
        const files = event.target?.files;
        if (files.length) {
          avatarName.value = files[0].name;
          const fileData = files[0];
          getBase64(fileData);
        }
      }
    }

    const errorMessage = (message) => {
      sendNotification({
        id: Symbol('profile_picture'),
        message: message,
        type: 'danger',
        icon: 'error',
        persist: false,
        title: 'Something went wrong!',
      });
    }

    const makeImageUpdate = async ({file_name,file_content}) : Promise<any> => {
      if (file_content !== null) {
        let trimmedContent =  (file_content || '').split(',')[1].trim()
        const customerProfilePicture = await updateCustomerPicture(file_name,trimmedContent)
        if (customerProfilePicture) {
            sendNotification({
              id: Symbol('profile_picture'),
              message: `Profile picture updated.`,
              type: 'success',
              icon: 'check',
              persist: false,
              title: 'Profile Picture!',
            });
          }
        eventBus.$emit('profilePictureUpdate', file_content.toString(), true)
        setTimeout(()=>{
          router.go(0)
        }, 300)
        } else {
          sendNotification({
            id: Symbol('profile_picture'),
            message: `Unable to update your profile picture. Please try later.`,
            type: 'danger',
            icon: 'error',
            persist: false,
            title: 'Something went wrong!',
          });
        eventBus.$emit('profilePictureUpdate', '' , false)
      }
    };
    const deleteImage = async () : Promise<any> => {
      const customerProfilePicture = await updateCustomerPicture('','')
      if (customerProfilePicture) {
        sendNotification({
          id: Symbol('profile_picture'),
          message: `Profile picture deleted.`,
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Profile Picture!',
        });
      }
      eventBus.$emit('profilePictureUpdate', '', true)
      setTimeout(()=>{
        router.go(0)
      }, 300)
    };

    const ifTextHasSpace = (text) => {
      if (text.includes(' ')) {
        let name = text.split(' ')
        return name[0]
      }
      return text
    }

    const checkRoute = computed(() => {
      if(route.value.params.quoteId){
        return true
      }
      if(route.value.params.offerId){
        return true
      }
      if(route.value.params.orderId){
        return true
      }
    });

    return {
      ifTextHasSpace,
      isAuthenticated,
      errorMessage,
      uploadFile,
      avatar,
      getUserInitial,
      userDetails,
      logout,
      clear,
      sidebarLinkGroups,
      title,
      isOnSubpage,
      goToTopLevelRoute,
      makeImageUpdate,
      hideAside,
      deleteImage,
      visible,
      checkRoute
    };
  },
});
