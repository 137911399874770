import type { RawLocation } from 'vue-router';

type LinkGroup = { items: LinkGroupItem[] };
type LinkGroupItem = { label: string, icon: string, link?: RawLocation, listeners?: Record<string, () => (Promise<void> | void)> };

export const useSidebarLinkGroups = () => {
  const sidebarLinkGroups : LinkGroup[] = [
    {
      items: [
        {
          label: 'Personal Data',
          icon: 'profile',
          link: { name: 'customer-my-profile' },
        },
        {
          label: 'Addresses',
          icon: 'address',
          link: { name: 'customer-addresses-details' },
        },
        {
          label: 'Wallet',
          icon: 'wallet',
          link: { name: 'customer-my-wallet' },
        },
        {
          label: 'Orders & Quotes',
          icon: 'bag',
          link: { name: 'customer-order-history' },
        },
        {
          label: 'Placed Offers & Bids',
          icon: 'offers',
          link: { name: 'customer-my-offers' },
        },
        {
          label: 'Manage Rentals',
          icon: 'rentals',
          // link: { name: 'customer-my-profile' },
        },
        {
          label: 'Maintenance Services',
          icon: 'maintenance',
          link: { name: 'customer-my-maintenance' },
        }
      ],
    }
  ];

  return { sidebarLinkGroups };
};
