<template>
  <div>
    <div @click="launchFilePicker()">
      <slot name="activator">
        <div class="flex align-items--center gap-2">
          <span class="label-text text-14">Change picture</span>
        </div>
      </slot>
    </div>
    <input
      type="file"
      ref="file"
      :name="uploadFieldName"
      @change="onFileChange($event.target.name, $event.target.files, $event)"
      style="display:none"
    >
  </div>
</template>

<script>
export default {
  name: 'image-input',
  data() {
    return {
      errorText: '',
      uploadFieldName: 'file',
      maxSize: 2048
    }
  },
  methods: {
    launchFilePicker() {
      this.$refs.file.click();
    },
    onFileChange(fieldName, file, event) {
      const { maxSize } = this
      let imageFile = file[0];
      let typesAllowed = ['image/jpeg','image/jpg', 'image/png','image/gif']
      if (file.length > 0) {
        let size = imageFile.size / maxSize / maxSize
        if (!imageFile.type.match('image.*')) {
          this.errorText = 'Please choose an image file'
          this.$emit('error', this.errorText)
        } else if (imageFile.type.includes(typesAllowed)) {
          this.errorText = 'Please choose image type from these JPEG, JPG, PNG, GIF.'
          this.$emit('error', this.errorText)
        } else if (size > 2) {
          this.errorText = `Your file is too big! Please select an image under 2MB's`
          this.$emit('error', this.errorText)
        } else {
          this.$emit('input', event)
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.label-text {
  color: #04AAB8;
  cursor: pointer;
}
.gap-2 {
  gap: 0.5rem;
}
</style>
